<template>
    <div id="Footer">

        <div class="container-fluid main-footer">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 text-center col-sm-12 tsg-section">
                    <img class="tsg-logo" src="../assets/img/tsgwhite.png" alt="TSG logo">
                    <p class="tsg-about">
                        © 2022 Test Server Games, Valenia RPG and any associated logos are trademarks, service marks, and/or registered trademarks of Test Server Games. "Discord" logos are registered trademarks or trademarks of Discord Inc.
                    </p>
                </div>
                <div class="col-lg-3 col-sm-12 offset-3">
                    
                </div>
                <div class="col-lg-3 col-sm-12"></div>
            </div>
            
            <div class="row middle-body mt-5">
                <div class="col-4 logo-area">
                    <img src="../assets/img/logo.png" alt="Valenia logo">
                </div>
                <div class="col-8 link-area">
                    <div class="footer-links">
                        <router-link to="/about">About</router-link>
                        <a target="_blank" href="https://www.iubenda.com/terms-and-conditions/15871647">Terms and Conditions</a>
                        <a target="_blank" href="https://www.iubenda.com/privacy-policy/15871647/legal">Privacy Policy</a>
                        <router-link to="/redirect/status">Status</router-link>
                        <router-link to="/contact">Contact</router-link>
                        <a href="/#"><i class="fab fa-twitter"></i></a>
                        <a href="/#"><i class="fab fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>

    </div> 
</template>

<script>
export default {
    name: "Footer",
    data: () => {
        return {
            icon: {
                iyzico: "https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/payment/iyzico-footer.png"
            }
        }
    },
}
</script>

<style lang="scss" src="@/assets/footer/footer.scss">

</style>