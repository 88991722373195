<template>
    <div id="CheckoutErrorView">
        <Navbar />
        <CheckoutError/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import CheckoutError from '@/components/Store/CheckoutError';
export default {
    name: 'CheckoutErrorView',
    components: {
        CheckoutError,
        Navbar,
        Footer
    },
}
</script>

