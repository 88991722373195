<template>
  <div id="ContactView">
      <Navbar />
      <Form />
      <Footer/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import Form from '@/components/Contact/Form.vue';

export default {
    name: 'ContactView',
    components: {
        Navbar,
        Footer,
        Form
    }
}
</script>

<style>

</style>