<template>
    <div id="Premium">

        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5 offset-lg-1" style="margin-top: 15vh">
                    <div class="heading">
                        BECOME A PREMIUM
                        <div class="title-line"></div>
                    </div>
                    <p class="desc mb-5">
                        Get extra benefits like auto-revive, 
                        auto-repair, 
                        cooldown decrease, 
                        item comparison and 
                        so much more with Valenia Premium 
                        to improve your gameplay.
                    </p>

                    <div class="row">
                        <div class="col-8 offset-lg-3 float-right">
                            <router-link to="/premium" class="promo-button v-button col-lg-8">
                                <span class="txt">See Features</span>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-5 text-center">
                    <img src="../../assets/img/ultimate.png" alt="mob picture" class="image">
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
#Premium{
    padding-top: 10vh;
    padding-bottom: 10vh;
    background-image: url("../../assets/img/chest_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.image {
    border-radius: 4px;
    width: 70%;
}
.title-line{
    background-image: url('../../assets/img/header_bottom_frame.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 6vh;
    margin-top: -1vh;
}
.heading{
    text-align: center;
    font-size: 3em;
    font-family: griffon,serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3vh;
    color: #E6E6E9;
}
.desc{
    color: #9999A1;
    font-size: 1.4em;
}
p.heading{
    font-size: 3.5em;
    font-weight: bold;
    color: #f5f5f5;
}
div.par-heading{
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3vh;
    color: #E6E6E9;
}
.descriptor{
    color: #9999A1;
    font-size: 1.2em;
}
.white-hr{
    border-color: #f5f5f5;
}
</style>

<style scoped lang="scss" src="@/assets/general.scss">
</style>