<template>
    <div id="MSSView">
        <MSS />
    </div>
</template>

<script>
import MSS from '@/components/Store/MSS.vue';

export default {
    name: 'MSSView',
    components: {MSS}
}
</script>

<style>

</style>