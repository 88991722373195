<template>
    <div id="Dashboard">
        asd
    </div>
</template>

<script>
export default {
    name: 'Dashboard'
}
</script>

<style>

</style>