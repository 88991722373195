<template>
    <div id="SectionDivider">
    </div>
</template>

<script>
export default {
    name: "SectionDivider"
}
</script>

<style scoped>
#SectionDivider {
    position: absolute;
    background-image: url("../../assets/img/section_divider.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 10vh;
    width: 60%;
    left: 20%;
    position: absolute;
    margin-top: -2.5vh;
}
</style>