<template>
    <div id="PremiumFAQ">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 text-center">
                    <p class="faq-title">
                        Frequently Asked Questions
                    </p>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-8 offset-lg-2 question-wrapper">
                    <div v-for="(q, index) in questions" @click="showQuestion(index)" :class='{"visible": q.isVisible}' :key="index" class="question-block">
                        <div class="row">
                            <div class="col-11">
                                <p class="question">{{q.question}}</p>
                            </div>
                            <div class="col-1 text-center">
                                <transition name="fade" mode="out-in">
                                    <i :key="q.index + 'notvisible'" v-if="!q.isVisible" class="fas fa-caret-down"></i>
                                    <i :key="q.index + 'visible'" v-else class="fas fa-caret-up"></i>
                                </transition>
                            </div>
                        </div>
                        <div v-if="q.isVisible" class="row">
                            <div class="col-12">
                                <p class="answer">{{q.answer}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PremiumFAQ",
    data(){
        return {
            questions: [
                {
                    question: "What is Valenia Premium?",
                    answer: "Becoming a Valenia Premium member unlocks VIP perks, including full access to all feature benefits, auto actions like auto-revive and auto repair, expanded storage for in-game materials, exclusive market and shop deals, and loads of additional bonuses all around the game.",
                    isVisible: false
                },
                {
                    question: "How Can I Refund?",
                    answer: "Since all the items you receive are virtual when you purchase a premium subscription, refund is not possible.",
                    isVisible: false
                },
                {
                    question: "Can I Change/Update My Subscription to Another Level?",
                    answer: "Yes, you can. We calculate the used time to make a discount and, save your money while switching levels.",
                    isVisible: false
                }
            ]
        }
    },
    methods: {
        showQuestion(index){
            for(let i = 0; i < this.questions.length; i++){
                if(i === index){
                    this.questions[i].isVisible = !this.questions[i].isVisible;
                }
                else{
                    this.questions[i].isVisible = false;
                }
            }
        }
    }
}
</script>

<style scoped>
#PremiumFAQ{
    margin-top: 10vh;
}
.faq-title{
    font-family: nocturne-serif, serif;
    color: #f5f5f5;
    font-size: 2.3em;
    font-weight: bold;
}
.question-block{
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 5vh;
}
.question-block:hover{
    cursor: pointer;
}
.question-block.visible{
    border-bottom: 1px solid rgba(255,255,255,0.5);
}
.question-block .question{
    font-family: nocturne-serif;
    color: #f5f5f5;
    font-size: 1.5em;
    user-select: none;
}
.question-block i .notvisible{
    font-size: 2.1em;
}
.question-block i{
    color: rgba(255,255,255,0.2);
}
.question-block.visible i{
    color: rgba(255,255,255,0.5);
}
.question-block .answer{
    transition: display .3s ease;
    color: #9999A1;
    font-family: 'Rubik', sans-serif;
}
</style>