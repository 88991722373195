<template>
    <div id="Navbar">

        <div class="valenia-nav">
            <div class="nav-bg"></div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 offset-lg-1 logo-area">
                        <div @click="$router.push('/')" class="navbar-logo"></div>
                    </div>
                    <div class="col-lg-8 offset-lg-1 link-area">
                        <div class="navbar-links float-right">
                            <router-link class="n-link" to="/about">About</router-link>
                            <!--<router-link class="n-link" to="/invite">Invite</router-link>-->
                            <router-link class="n-link" to="/redirect/invite">INVITE</router-link>
                            <router-link to="/premium" class="v-shop-link n-link">PREMIUM</router-link>
                            
                            <router-link v-if="!user" to="/login" class="v-login-link n-link">LogIn <i class="fab fa-discord"></i></router-link>
                            <span @click="alternateDropdown" v-else to="/dashboard" class="v-login-link n-link">{{user.username}} <i class="fas fa-caret-down"></i></span>
                            <div v-if="user" v-show="dropdownOpen" class="user-dropdown">
                                <div class="d-list">
                                    <!-- <router-link to="/profile" class="d-item"><i class="fas fa-user"></i> Profile</router-link> -->
                                    <router-link to="/logout" class="d-item">
                                        <i class="fas fa-power-off"></i> 
                                        Log out
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <div class="bottom-frame"></div>

    </div>
</template>

<script>
export default {
    name: "Navbar",
    data(){
        return {
            user: this.$store.getters.session,
            dropdownOpen: false
        }
    },
    methods: {
        alternateDropdown(){
            this.dropdownOpen = !this.dropdownOpen;
        }
    }
}
</script>

<style lang="scss" src="@/assets/navbar/navbar.scss">

</style>