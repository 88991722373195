<template>
    <div id="CreateDivider">
        <div class="divider-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="divider-title mb-4">
                            Create Your Own Character
                            
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <p class="promo-desc">
                            High-damage fireballs, incessant arrow rains,<br />
                            daggers from the shadows, impenetrable shields, and more...<br />
                            Choose the class that suits you best, and fight for your destiny!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="divider-bottom-frame"></div>
    </div>
</template>

<script>
export default {
    name: 'CreateDivider'
}
</script>

<style>
@import url('https://use.typekit.net/edx8ekp.css');
.divider-wrapper {
    background-image: url("../../assets/img/promo_bg.png");
    border-top: 2px solid white;
    border-bottom: 2px solid white;
    height: 40vh;
    padding: 6.5vh 0 1vh 0;
    background-color: #212121;
    color: #f5f5f5;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.divider-title{
    font-family: griffon,serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
}
.promo-desc {
    font-size: 1.45em;
}
.divider-bottom-frame {
    background-image: url('../../assets/img/header_bottom_frame.png');
    background-repeat: no-repeat;
    height: 12vh;
    width: 100%;
    position: absolute;
    margin-top: -15px;
}
</style>