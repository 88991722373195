<template>
    <div id="Payment">
        <div class="container-fluid pt-5 pb-5">
            <div ref="paymentForm" class="row">
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2 col-sm-12">
                    <div class="checkout-title">
                        Payment
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-md-8 offset-md-2 col-sm-12">
                    <div class="checkout-box pb-4">

                        <div v-if="isLoading" class="row">
                            <div class="col-12 text-center">
                                <p class="loading-text">
                                    <i class="fas fa-circle-notch fa-spin"></i><br>
                                    Loading
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <p class="subdetails-header">Billing Details</p>

                                <div class="row">
                                    <div class="col-lg-4 col-sm-12">
                                        <label>First Name</label>
                                        <input type="text" v-model="billing.name" :class="{'invalid': !validation.name}" class="form-control form-control-sm" placeholder="John" @change="validateFirstName">
                                    </div>
                                    <div class="col-lg-4 col-sm-12">
                                        <label>Last Name</label>
                                        <input type="text" v-model="billing.surname" :class="{'invalid': !validation.surname}" class="form-control form-control-sm" placeholder="Doe" @change="validateLastName">
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6 col-sm-12">
                                        <label>Identity Number</label>
                                        <input type="text" v-model="billing.identity_number" :class="{'invalid': !validation.identity_number}" placeholder="01234567890" class="form-control form-control-sm" @change="validateIdentity">
                                        <small class="text-secondary" @mouseenter="identityExplainer.show()" ref="identityExplainer" data-bs-toggle="tooltip" data-bs-placement="right" title="Iyzico needs your identity number in order to create an invoice for you.">
                                        <i class="fas fa-question-circle"></i> Why do we need this information?
                                        </small>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6 col-sm-12">
                                        <label>Phone Number</label>
                                        <input type="text" v-model="billing.phone_number" :class="{'invalid': !validation.phone_number}" placeholder="+905555555555" class="form-control form-control-sm" @change="validatePhone">
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-6 col-sm-12">
                                        <label>E-mail Address</label>
                                        <input type="text" v-model="billing.email" :class="{'invalid': !validation.email}" placeholder="johndoe@mail.com" class="form-control form-control-sm" @change="validateEmail">
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-8 col-sm-12">
                                        <label>Address</label>
                                        <textarea v-model="billing.address" rows="4" :class="{'invalid': !validation.address}" placeholder="" class="form-control form-control-sm" @change="validateAddress" style="resize: none;">
                                        </textarea>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-lg-4 col-sm-12">
                                        <label>District</label>
                                        <input type="text" v-model="billing.district" :class="{'invalid': !validation.district}" class="form-control form-control-sm" placeholder="Kadikoy" @change="validateDistrict">
                                    </div>
                                    <div class="col-lg-4 col-sm-12">
                                        <label>City</label>
                                        <input type="text" v-model="billing.city" :class="{'invalid': !validation.city}" class="form-control form-control-sm" placeholder="Istanbul" @change="validateCity">
                                    </div>
                                </div>
                                
                                <div class="row mt-2">
                                    <div class="col-lg-6 col-sm-12">
                                        <label>Country</label>
                                        <select v-model="billing.country" class="form-control form-control-sm" :class="{'invalid': !validation.country}" placeholder="Select Country" @change="validateCountry">
                                            <option v-for="country in countries" :key="country.id" :value="country.code">{{ country.name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12">
                                <p class="subdetails-header">Confirmation</p>

                                <div class="row mt-3">
                                    <div class="col-lg-12 mb-2 col-sm-12">
                                        <div class="form-check">
                                            <input type="checkbox" :class="{'invalid': !validation.agree}" class="form-check-input" v-model="agree" @change="validateAgree">
                                            <label class="form-check-label">
                                                I have read and agreed to the <a target="_blank" href="https://www.iubenda.com/terms-and-conditions/15871647" class="tos-text">Terms and Conditions</a> and <span class="remote-purchase-dialog" @click="showDSC">Distance Sales Contract</span>.
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-lg-12 mb-2 col-sm-12">
                                        <small class="text-secondary">
                                            After this step you will be redirected payment page and
                                            your payment will be handled by <strong>Iyzico Payment</strong>.
                                        </small>
                                    </div>
                                    <div class="col-lg-8 col-sm-12">
                                        <button ref="completeButton" @click="onComplete" class="btn btn-primary col-12">Complete Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12"><hr></div>
                            <div class="col-12 text-center">
                                <p class="text-secondary">
                                    Secure payment partners:
                                </p>
                                <img :src="icons.iyzico" class="payment-icon" alt="Iyzico Icon">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import countryList from '@/assets/countries.json';
import swal from 'sweetalert2';
import axios from 'axios';

axios.defaults.withCredentials = true;
export default {
    name: 'CompletePayment',
    data(){
        return {
            countries: countryList,
            isLoading: false,
            icons: {
                mastercard: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/payment/mastercard.png',
                visa: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/payment/visa.png',
                iyzico: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/payment/secure_partners.png'
            },
            agree:false,
            billing: {
                name: "",
                surname: "",
                phone_number: "",
                identity_number: '',
                email: "",
                country: "TR",
                address: '',
                district: '',
                city: ''
            },
            validation: {
                name: true,
                surname: true,
                phone_number: true,
                email: true,
                country: true,
                agree: false,
                address: true,
                identity_number: true,
                district: true,
                city: true
            },
            identityExplanier: this.$refs.identityExplanier
        }
    },
    methods: {
        async onComplete(){
            const button = this.$refs.completeButton;
            this.disableButton(button);


            const validationResult = this.validateAll();
            if(validationResult.result != 1){
                swal.fire({
                    icon: 'error',
                    type: 'error',
                    title: 'Oops...',
                    text: validationResult.message
                });
                this.enableButton(button);
            }
            else {
                const data = {
                    session: this.$store.getters.session,
                    customer: this.billing,
                    address: {
                        contactName: `${this.billing.name} ${this.billing.surname}` ,
                        city: this.billing.city,
                        district: this.billing.district,
                        country: this.billing.country,
                        address: this.billing.address,
                        zipCode: ''
                    },
                    subscription: {
                        type: this.$store.state.buyingPremium,
                        timeshort: this.$store.state.buyingTimeshort
                    },
                    buyingPackage: this.$store.state.buyingPackage,
                    billing: this.billing,
                    payment: this.payment,
                    timeshort: this.$store.state.buyingTimeshort
                }
                const invoiceDetails = {
                    name: this.billing.name,
                    surname: this.billing.surname,
                    address: this.billing.address + " " + this.billing.district + " " + this.billing.city,
                    phone: this.billing.phone_number,
                    email: this.billing.email,
                    product: data.buyingPackage.name,
                    price: data.buyingPackage.price,
                    payment_method: "iyzico - Online Payment",
                    invoice_address: this.billing.address + " " + this.billing.district + " " + this.billing.city,
                    selling_count: this.$store.state.buyingTimeshort,
                }
                this.$store.commit('setInvoiceDetails', invoiceDetails);
                axios.post(`${this.$store.getters.server}/store/complete`, data)
                .then(async request => {
                    this.enableButton(button);
                    window.location = request.data;
                        // this.$store.commit('setBoughtPackage', {...request.data.payment, ...request.data.package});
                        // this.$router.push('/payment/complete');
                })
                .catch(error => {
                    this.enableButton(button);
                    console.log(error);
                    if(error.response.status === 500){
                        swal.fire({
                            icon: 'error',
                            type: 'error',
                            title: 'Oops...',
                            text: error.response.data.error.message,
                        })
                        .then(() => {
                            this.$router.go();
                        });
                    }
                    console.log(error);
                })
                
            }
            
        },
        disableButton(button){
            button.disabled = true;
            button.innerHTML = `<i class="fas fa-circle-notch fa-spin"></i> Processing`;
            button.blur();
        },
        enableButton(button){
            button.innerHTML = `Complete Payment`;
            button.disabled = false;
        },
        validateAll(){
            let validations = [];
            for(const key of Object.keys(this.validation)){
                if(key != 'payment' && key != 'agree'){
                    console.log({key});
                    validations.push(this.validation[key] && this.billing[key].length > 0);
                }
                else if(key == 'agree'){
                    validations.push(this.validation['agree'] && this.agree);
                }
                else {
                    continue;
                }
            }
            const result = validations.every(item => item);

            if(result){
                return {result: 1}
            }
            else{
                return {result: 0, message: 'Please check all the fields, do not leave any empty.'}
            }
        },
        validateFirstName(){
            this.validation.firstname = this.billing.firstname.match(new RegExp('[A-Za-z]{3,}'))
        },
        validateLastName(){
            this.validation.firstname = this.billing.firstname.match(new RegExp('[A-Za-z]{3,}'))
        },
        validatePhone(){
            this.validation.phone = this.billing.phone.match(new RegExp('[+][0-9]{10,14}'))
        },
        validateAgree(){
            this.validation.agree = this.agree;
        },
        validateEmail(){
            this.validation.email = this.billing.email.match(new RegExp('[a-z0-9]{2,}[@]{1}[a-z0-9]{2,}[.]{1}[a-z]{1,}'))
        },
        validateAddress(){
            this.validation.address = this.billing.address.length > 0;
        },
        validateCountry(){
            this.validation.email = this.billing.country.match(new RegExp('[A-Za-z]{3,}'))
        },
        validateIdentity(){
            this.validation.email = this.billing.country.match(new RegExp('[0-9]{9,15}'))
        },
        validateDistrict(){
            this.validation.email = this.billing.country.match(new RegExp('[A-Za-z]{1,}'))
        },
        validateCity(){
            this.validation.email = this.billing.country.match(new RegExp('[A-Za-z]{2,}'))
        },
        validateCardHolder(){
            this.validation.payment.cardholder = this.payment.cardholder.match(new RegExp('[A-Za-z]{5,}'))
        },
        validateCCNumber(){
            this.validation.payment.cardnumber = this.payment.cardnumber.match(new RegExp('^((4\\d{3})|(5[1-5]\\d{2})|(6011)|(34\\d{1})|(37\\d{1}))-?\\s?\\d{4}-?\\s?\\d{4}-?\\s?\\d{4}|3[4,7][\\d\\s-]{15}$'))
            this.onCCNumberEnter(this.payment.cardnumber);
        },
        validateExpiry(){
            this.validation.payment.expiry = this.payment.expiry.match(new RegExp('^(0[1-9]|1[0-2])\\/?(2[2-9]{1})$'));
            this.onExpiryEnter(this.payment.expiry);
        },
        validateCVV(){
            this.validation.payment.cvv = this.payment.cvv.match(new RegExp('^[0-9]{3,4}$'));
        },
        onExpiryEnter(value){
            value = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
            if(value.length > 2){
                let newval = "";
                for(let i = 0; i < value.length; i++){
                    if(i != 2){
                        newval += value[i];
                    }else{
                        newval += "/" + value[i];
                    }
                }
                this.payment.expiry = newval;
            }
            else this.payment.expiry = value;
        },
        onCCNumberEnter(value) {
            var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
            var matches = v.match(/\d{4,16}/g);
            var match = matches && matches[0] || ''
            var parts = []

            for (let i=0, len = match.length; i<len; i+=4) {
                parts.push(match.substring(i, i+4))
            }

            if (parts.length) {
                this.payment.cardnumber = parts.join(' ')
            } else {
                this.payment.cardnumber = value
            }
        }
    },
    created(){
    }
}
</script>

<style lang="scss" scoped src="@/assets/store/checkout/checkout.scss"></style>