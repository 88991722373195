<template>
    <div id="CheckoutView">
        <Navbar />

        <CheckoutBody />

        <Footer />
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue';
import Footer from '../../components/Footer.vue';
import CheckoutBody from '../../components/Store/Checkout.vue';

export default {
    name: "Checkout",
    components: {Navbar, Footer, CheckoutBody}
}
</script>

<style>

</style>