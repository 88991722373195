<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<style lang="scss" src="@/assets/general.scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#app{
  margin-bottom: 0;
  background-color: #0F1C20;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
