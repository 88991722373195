<template>
    <div id="Chest">

        <div class="chest-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 offset-lg-1" style="margin-top: 15vh;">
                        <div class="heading">
                            LOOT EPIC TREASURES
                            <div class="title-line"></div>
                        </div>
                        <p class="desc">
                            We know that you love grinding.
                            Collect gold, experience, and rare
                            items from the treasure chests.
                        </p>
                    </div>

                    <div class="col-lg-5 text-right"> <!--  data-aos="fade-left" data-aos-delay="500" -->
                        <img class="chest-image" src="../../assets/img/chest_img.png" alt="mob picture">
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "ChestFeatured",
    data: () => {
        return {
            chestPicture: "https://globalcdn.valeniarpg.com/website/ultimate_chest1.png"
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.chest-wrapper{
    padding-top: 8vh;
    background-image: url("../../assets/img/chest_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 10vh;
}
.title-line{
    background-image: url('../../assets/img/header_bottom_frame.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 6vh;
    margin-top: -1vh;
}
.chest-image{
    width: 100%;
}
.image {
    border-radius: 4px;
}
.heading{
    font-size: 3em;
    margin-bottom: 3vh;
    color: #E6E6E9;
    font-family: griffon,serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
}
.desc{
    color: #9999A1;
    font-size: 1.4em;
}
p.heading{
    font-size: 3.5em;
    font-weight: bold;
    color: #f5f5f5;
}
div.par-heading{
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3vh;
    color: #E6E6E9;
}
.descriptor{
    color: #9999A1;
    font-size: 1.2em;
}
.white-hr{
    border-color: #f5f5f5;
}
.hunt-upper-frame {
    background-image: url('../../assets/img/hunt_upper_divider.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 22vh;
    width: 100%;
    position: absolute;
    margin-top: -15vh; 
}
</style>