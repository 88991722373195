<template>
    <div id="Mobs">
        <div class="hunt-upper-frame"></div>
        <div class="mob-wrapper">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-5 offset-1"> <!--  data-aos="fade-right" data-aos-delay="500" -->
                        <img class="image" src="../../assets/img/hunt_img.png" alt="mob picture">
                    </div>

                    <div class="col-5" style="margin-top: 12vh;">
                        <div class="heading">
                            HUNT MIGHTY CREATURES
                            <div class="title-line"></div>
                        </div>
                        <p class="desc">
                            There will be some random spawns of enemies on your text channel in server
                            that you can hunt with a single command and fight your destiny to loot rewards, 
                            level up your character and complete quests.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "MobFeatured",
    data: () => {
        return {
            mobPicture: ""
        }
    },
    methods: {

    }
}
</script>

<style scoped>
@import url('https://use.typekit.net/edx8ekp.css');
.mob-wrapper{
    padding-top: 10vh;
    background-image: url("../../assets/img/hunt_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.image {
    width: 100%;
    border-radius: 4px;
}
.title-line{
    background-image: url('../../assets/img/header_bottom_frame.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 6vh;
    margin-top: -1vh;
}
.heading{
    font-size: 3em;
    margin-bottom: 3vh;
    color: #E6E6E9;
    font-family: griffon,serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
}
.desc{
    color: #9999A1;
    font-size: 1.4em;
}
.hunt-upper-frame {
    background-image: url('../../assets/img/hunt_upper_divider.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 22vh;
    width: 100%;
    position: absolute;
    margin-top: -13.5vh; 
}
</style>