<template>
    <div id="LoginSuccesful">

    </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;


export default {
    name: 'LoginSuccesful',
    async created(){
        try{
            let r = await axios.get(`${this.$store.getters.server}/auth/out`);
            if(r.status == 200){
                this.$store.commit('setSession', undefined);
                this.$router.push('/');
            }
            else{
                this.$router.push('/');
            }
        }
        catch(err){
            console.log(err);
            location.reload();
        }
    }
}
</script>

<style>

</style>