import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'vweb'
})

export default createStore({
  state: {
    server: "http://localhost:3169",
    session: null,
    buyingPremium: null,
    buyingPackage: null,
    buyingTimeshort: null,
    boughtPackage: null,
    invoiceDetails: {}
  },
  getters: {
    server(state){
      return state.server;
    },
    session(state){
      return state.session;
    }
  },
  mutations: {
    setSession(state, payload){
      state.session = payload;
    },
    setBuyingPremium(state, payload){
      state.buyingPremium = payload;
    },
    setBuyingTimeshort(state, payload){
      state.buyingTimeshort = payload;
    },
    registerBuyingPackage(state, payload){
      state.buyingPackage = payload;
    },
    setBoughtPackage(state, payload){
      state.boughtPackage = payload;
    },
    clearBoughtPackage(state){
      state.buyingPackage = null;
      state.buyingPremium = null;
      state.buyingTimeshort = null;
      state.boughtPackage = null;
    },
    setInvoiceDetails(state, payload){
      state.invoiceDetails = {
        name: payload.name,
        surname: payload.surname,
        address: payload.address,
        phone: payload.phone,
        email: payload.email,
        product: payload.product,
        sell_count: payload.sell_count,
        payment_method: payload.payment_method,
        invoice_address: payload.address
      }
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
