<template>
    <div id="LoginSuccesful">

    </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;


export default {
    name: 'LoginSuccesful',
    async created(){
        try{
            let r = await axios.get(`${this.$store.getters.server}/auth/get`);
            if(r.status == 200){
                this.$store.commit('setSession', JSON.parse(JSON.stringify(r.data)));
                this.$router.push('/');
            }
            else{
                this.$router.push('/login');
            }
        }
        catch(err){
            this.$router.push('/');
        }
    }
}
</script>

<style>

</style>