<template>
    <div id="LoginView">
        <LoginComponent />
    </div>
</template>

<script>
import LoginComponent from '@/components/Account/Login';
export default {
    name: "Login",
    components: {LoginComponent}
}
</script>

<style>

</style>