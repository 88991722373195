<template>
    <div id="AboutValenia">
        <div class="container-fluid mt-5 about-wrapper">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-12 text-center text-center">
                    <h2 class="page-title">About Valenia</h2>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-6 offset-lg-3 col-md-12 text-center">
                    <p class="v-about">
                        Valenia is an MMORPG bot, which you can create a character, select a class, loot treasure chests, hunt units, level up, challenge other players across the Discord, grind, equip or sell items, and more.
<br><br>
The main idea is to push the limits of Discord and create a better experience while chatting or talking to your friends on servers.
<br><br>
What's more, Valenia is not based on just emojis or text. Everything you see is visual content created by our image process system. Every time you loot a chest, hunt an enemy, or equip/ unequip an item, we can show it to you with high-quality visuals.
                    </p>
                </div>
            </div>
        </div>
        <MobFeatured />
        <SectionDivider />
        <ChestFeatured />
        <SectionDivider />
        <ChallengeFeatured />
        <SectionDivider />
    </div>
</template>

<script>
import ChestFeatured from '../Home/ChestFeatured.vue';
import MobFeatured from '../Home/MobFeatured.vue';
import ChallengeFeatured from '../Home/Challenge.vue';
import SectionDivider from '@/components/Home/SectionDivider.vue';


export default {
    name: 'AboutValenia',
    components: {ChestFeatured, MobFeatured, ChallengeFeatured, SectionDivider}
}
</script>

<style>
@import url('https://use.typekit.net/edx8ekp.css');
#AboutValenia {
    padding-top: 10vh;
    margin-top: -5vh;
    background-image: url("../../assets/img/chest_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.about-wrapper {
    padding-bottom: 15vh;
}
.page-title{
    color: #f5f5f5;
    font-family: griffon,serif;
    font-weight: 700;
    font-style: normal;
    font-size: 2.5em;
}
.v-about{
    color: #9999A1;
    font-family: 'Rubik', sans-serif;
    font-size: 1.2em;
}
</style>