<template>
    <div id="CompletePaymentView">
        <Navbar />
        <CompletePaymentBody />
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import CompletePaymentBody from '@/components/Store/CompletePayment';

export default {
    name: 'CompletePayment',
    components: {Navbar, Footer, CompletePaymentBody}
}
</script>

<style>

</style>