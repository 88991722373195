<template>
    <div id="PremiumView">
        <Navbar />

        <PremiumFeatures />
        <PremiumTable />
        <PremiumFAQ />

        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';

import PremiumTable from '../components/Premium/Table.vue';
import PremiumFeatures from '../components/Premium/Features.vue';
import PremiumFAQ from '../components/Premium/FAQ.vue';
//import FeaturedSlider from '../components/Store/FeaturedSlider.vue';
//import CategoriesSidebar from '../components/Store/CategoriesSidebar.vue';

export default {
    name: "PremiumView",
    components: {Navbar, Footer, PremiumTable, PremiumFeatures, PremiumFAQ}
}
</script>

<style>
#PremiumView{
    background-image: url('https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/backgrounds/premium_bg.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>