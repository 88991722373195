<template>
    <div id="AboutView">
        <Navbar />
        <About />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';


import About from '../components/About/About.vue';
export default {
    name: 'AboutView',
    components: {Navbar, Footer, About}
}
</script>

<style>

</style>