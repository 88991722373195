import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

import Home from '../views/Home.vue'
import LoginView from '../views/Login.vue';
import PremiumView from '../views/Premium.vue';
import StatusView from '../views/Status.vue';
import AboutView from '../views/About.vue';
import DashboardView from '../views/Dashboard.vue';
import CheckoutErrorView from '../views/CheckoutError.vue';

import CheckoutView from '../views/Store/Checkout.vue';
import CompletePaymentView from '../views/Store/CompletePayment.vue';
import PaymentCompleteView from '../views/Store/PaymentResult.vue';

import LoginSuccessfulView from '../operations/LoginSuccessful.vue';
import LogOutView from '../operations/Logout.vue';

import RedirectView from '../views/Redirect.vue';

import ContactView from '../views/Contact.vue';

import MSSView from '../views/MSSView.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: "/login", name: "Login", component: LoginView },
  { path: '/premium', name: 'PremiumView', component: PremiumView },
  { path: '/status', name: 'StatusView', component: StatusView },
  { path: '/about', name: 'AboutValenia', component: AboutView },
  { path: '/redirect/:page', name: 'RedirectView', component: RedirectView },
  { path: '/dashboard', name: 'Dashboard', component: DashboardView, meta: {requiresAuthentication: true} },
  { path: '/login-successful', name: 'LoginSuccessful', component: LoginSuccessfulView, meta: {requiresAuthentication: false} },
  { path: '/logout', name: 'LogOutView', component: LogOutView, meta: {requiresAuthentication: true} },
  { path: '/contact', name: 'ContactView', component: ContactView },
  { path: '/distance-sales-contract', name: 'MSSView', component: MSSView, meta: {requiresAuthentication: true, requiresBuyingPackage: true} },

  { path: '/checkout', name: 'CheckoutView', component: CheckoutView, meta: {requiresAuthentication: true, requiresBuyingPackage: true} },
  { path: '/checkout/error', name: 'CheckoutErrorView', component: CheckoutErrorView, meta: {requiresAuthentication: true, requiresBuyingPackage: false} },
  { path: '/complete-payment', name: 'CompletePayment', component: CompletePaymentView, meta: {requiresAuthentication: true, requiresBuyingPackage: true}  },
  { path: '/payment/result', name: 'PaymentComplete', component: PaymentCompleteView, meta: {requiresAuthentication: true, requiresBuyingPackage: true}  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if(to.fullPath.includes('#') || to.fullPath === from.fullPath) {return {}}
    const dum = {to, from, savedPosition, pos: 0}
    return { top: dum.pos }
  }
})

router.beforeEach((to, from ,next) => {
  if(to.meta?.requiresBuyingPackage){
    if(store.state.buyingPremium === null){
      next('/');
    }
  }
  if(to.meta?.requiresAuthentication){
    if(store.getters.session){
      next();
    }
    else{
      next('/login');
    }
  }

  next();
})

export default router
