<template>
    <div id="CheckoutBody">
        <div class="container-fluid pt-5 pb-5">
            <div class="row">
                <div class="col-8 offset-2">
                    <div class="checkout-title">
                        Checkout
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-8 offset-2">
                    <div class="checkout-box">
                        
                        <div v-if="isErrored" class="row">
                            <div class="col-12 text-center">
                                <p class="errored-text">
                                    <i class="fas fa-times"></i><br>
                                    Something went wrong
                                </p>
                            </div>
                        </div>

                        <div v-if="!isErrored && isLoading" class="row">
                            <div class="col-12 text-center">
                                <p class="loading-text">
                                    <i class="fas fa-circle-notch fa-spin"></i><br>
                                    Loading
                                </p>
                            </div>
                        </div>

                        <div v-if="!isErrored && !isLoading" class="row">
                            <div class="col-lg-6 col-sm-12">
                                <p class="subdetails-header">Subscription Details</p>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        Hey <strong>{{user.username}}!</strong><br>
                                        You are buying <strong>{{buyingPackage.name}}</strong>!
                                        <br/>
                                        Your subscription will start <strong>today</strong> 
                                        and will end on <strong>{{ getPackageEndTime(buyingPackage.times[this.time]) }}</strong>.
                                    </div>
                                </div>

                                <p class="subdetails-header">Coupons</p>
                                
                                <div class="row mb-3">
                                    <div class="col-sm-12 mb-3">
                                        If you have a coupon, feel free to use it!
                                    </div>
                                    <div class="col-5">
                                        <input type="text" class="form-control form-control-sm" placeholder="Coupon Code" v-model="coupon.code" :disabled="guildDiscounts.isApplied || coupon.isApplied">
                                    </div>
                                    <div class="col-3">
                                        <button ref="applybutton" @click="applyCoupon" v-show="!coupon.isApplied" :disabled="coupon.code.length === 0" class="btn btn-primary btn-sm col-12">Apply</button>
                                        <button ref="removebutton" @click="removeCoupon" v-show="coupon.isApplied" class="btn btn-danger btn-sm col-12">Remove</button>
                                    </div>
                                </div>

                                <p class="subdetails-header">Guild Discounts</p>
                                <div class="row guild-discount-row mb-3">
                                    <div v-if="guildDiscounts.available.length == 0" class="col-12">
                                        You are not in any of the <router-link to="/about#partners">Valenia Partner</router-link> guilds.
                                    </div>
                                    <div v-else-if="guildDiscounts.available.length > 0" class="col-12">
                                        
                                        <div v-for="guild in guildDiscounts.available" @click="selectGuildDiscount(guild)" :key="guild.code" class="guild-discount-wrapper" :class="{'active': coupon.code == guild.code}">
                                            {{guild.name}}
                                            <span class="code"><strong>Discount:</strong> -{{guild.discount[0] == 0 ? '%' : '$'}}{{guild.discount[1]}}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                                <p class="subdetails-header">Summary</p>
                                <div class="checkout-summary">

                                    <div class="summary-row">
                                        <span class="name">
                                            {{ buyingPackage.name }} ({{buyingPackage.times[this.time].name}})
                                        </span>
                                        <span class="value">
                                            ${{buyingPackage.times[this.time].prices.usd}}
                                        </span>
                                    </div>

                                    <div class="summary-row" v-if="currentPackageDiscount.is">
                                        <span class="name">
                                            {{currentPackageDiscount.name}}
                                        </span>
                                        <span class="value discounted">
                                            -${{currentPackageDiscount.value}}
                                        </span>
                                    </div>

                                    <div class="summary-row coupon-row" v-if="coupon.isApplied">
                                        <span class="name">
                                            {{coupon.code}}
                                        </span>
                                        <span class="value discounted">
                                            -{{coupon.discount[0] == 0 ? '%' : '$'}}{{coupon.discount[1]}}
                                        </span>
                                    </div>

                                    <div class="summary-row total-row">
                                        <span class="name">
                                            Total Price
                                        </span>
                                        <span class="value">
                                            ${{total}}
                                        </span>
                                    </div>
                                </div>

                                <div class="checkout-complete mt-4">
                                    <div class="row">
                                        <div class="col-8 offset-4">
                                            <button @click="continueCheckout" ref="completeButton" class="btn btn-primary col-12">Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;

import swal from 'sweetalert2';
import moment from 'moment';

export default {
    name: 'CheckoutBody',
    data(){
        return {
            isLoading: true,
            isErrored: false,
            currentPackageDiscount: {
                is: false,
                name: '',
                value: 0
            },
            coupon: {
                isApplied: false,
                code: '',
                discount: []
            },
            guildDiscounts: {
                isApplied: false,
                selected: {},
                available: [
                ]
            },
            user: this.$store.getters.session,
            buyingPackage: {
                times: []
            },
            time: this.$store.state.buyingTimeshort,
            currency: 'usd',
            premiumNames: [
                'Common Premium',
                'Uncommon Premium',
                'Rare Premium',
                'Epic Premium',
                'Legendary Premium',
                'Mythic Premium',
                'Ultimate Premium'
            ]
        }
    },
    computed: {
        getCouponDiscount(){
            if(!this.coupon.isApplied) return [0,0];

            if(this.guildDiscounts.isApplied){
                return this.guildDiscounts.selected.discount;
            }
            else {
                return this.coupon.discount;
            }
        },
        total(){
            let mainPrice = this.buyingPackage.times[this.time].prices[this.currency];
            const couponDiscount = this.coupon.discount;
            if(couponDiscount[0] == 0){
                mainPrice -= (mainPrice * couponDiscount[1])/100
            }
            else if(couponDiscount[0] == 1){
                mainPrice -= couponDiscount[1];
            }

            if(this.currentPackageDiscount.is){
                mainPrice -= this.currentPackageDiscount.value;
            }

            return (mainPrice).toFixed(2);           
        },
        paymentFee(){
            let mainPrice = this.buyingPackage.times[this.time].prices[this.currency];
            return Math.max(parseFloat((mainPrice * 0.001).toFixed(2)), 0.50).toFixed(2);
        }
    },
    methods: {
        continueCheckout(){
            this.$refs.completeButton.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Continue';
            this.$refs.completeButton.blur();
            this.$refs.completeButton.disabled = true;

            setTimeout(() => {
                // check necessary constraints
                const buyingPackage = {
                    package: this.buyingPackage,
                    current: this.currentPackage,
                    coupon: this.coupon,
                    total: this.total
                }

                this.$store.commit('registerBuyingPackage', buyingPackage);
                
                this.$router.push('/complete-payment');
            }, 3000);
        },
        async applyCoupon(){
            const code = this.coupon.code;
            this.$refs.applybutton.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i> Applying';
            try{
                const response = await axios.post(`${this.$store.getters.server}/store/check-coupon`, {code});
                console.log(response.data);
                if(response.data.status == 1){
                    this.coupon.isApplied = true;
                    this.coupon.code = code;
                    this.coupon.discount = response.data.coupon.discount;

                    this.$refs.applybutton.innerHTML = 'Apply';
                }
                else {
                    this.$refs.applybutton.innerHTML = 'Apply';
                    alert('Invalid coupon code');
                }
            }
            catch(err){
                this.$refs.applybutton.innerHTML = 'Apply';
                alert('Something went wrong..');
            }
            
        },
        removeCoupon(){
            this.coupon.isApplied = false;
            this.coupon.discount = [0,0];
            this.coupon.code = '';
        },
        getPackageEndTime(timeSignature){
            const milliseconds = timeSignature.numerical;
            return moment(Date.now() + milliseconds).format('DD MMMM YYYY').toString();
        },
        selectGuildDiscount(guild){
            if(this.coupon.code == guild.code){
                this.coupon.isApplied = false;
                this.coupon.code = '';
                this.guildDiscounts.isApplied = false;
                this.guildDiscounts.selected = {};
                this.coupon.discount = [0,0];
            }
            else {
                this.coupon.isApplied = true;
                this.coupon.code = guild.code;
                this.guildDiscounts.isApplied = true;
                this.guildDiscounts.selected = guild;
                this.coupon.discount = guild.discount;
            }
            
        },
        setCurrentPackageDiscount(){
            if(this.currentPackage){
                if(this.currentPackage.type >= this.buyingPackage.code) return;

                const now = moment(Date.now());
                const end = moment(this.currentPackage.end);
                const remainingDays = end.diff(now, 'days');

                const estimated_time = this.currentPackage.end - this.currentPackage.start;
                let timeshort;
                for(let i = 0;i < this.currentPackage.times.length; i++){
                    if(this.currentPackage.times[i].numerical == estimated_time){
                        timeshort = i;
                        break;
                    }
                }

                this.currentPackageDiscount.is = true;
                this.currentPackageDiscount.name = `Discounted ${remainingDays} days of ${this.currentPackage.name}`;

                const priceperday = this.currentPackage.times[timeshort].prices[this.currency] / 30;
                this.currentPackageDiscount.value = (remainingDays * priceperday).toFixed(2);
                if(this.currentPackageDiscount.value > this.buyingPackage.times[this.time].prices[this.currency]){
                    this.currentPackageDiscount.is = false;
                }
            }
        },
        async getPackage(){
            try{
                let r = await axios.post(`${this.$store.getters.server}/store/get/premium`, {packageId: this.$store.state.buyingPremium, time: this.$store.state.buyingTimeshort, currency: 'usd'});
                console.log(r.data)
                if(r.data.status === 1){
                    this.buyingPackage = r.data.package;
                    console.log(this.buyingPackage);
                }
                else if(r.data.status === 2){
                    this.buyingPackage = r.data.package;
                    this.currentPackage = r.data.current;
                    // this.setCurrentPackageDiscount();
                }
                else if(r.data.status === -1){
                    this.isErrored = true;
                    swal.fire({
                        title: "You don't play Valenia!",
                        text: "We couldn't find you in our player base. You should start playing first!",
                        type: "error"
                    }).then(function() {
                        window.location = '/start';
                    });
                }
                else{
                    swal.fire('Something went wrong', 'Please go back to premium page and buy again.', 'error');
                    this.isErrored = true;
                }
            }
            catch(err){
                console.log(err);
                if(err.response.status == 403){
                    this.$router.push('/logout');
                }
                this.isErrored = true;
                this.isLoading = false;
            }
        },
    },
    async created(){
        await this.getPackage();

        this.isLoading = false;
    }
}
</script>

<style lang="scss" scoped src="@/assets/store/checkout/checkout.scss">

</style>