<template>
    <div id="CheckoutError">
        <div class="container-fluid padding-lg">
            <div class="row">
                <div class="col-lg-12 text-center col-sm-12">
                    <div class="checkout-title">
                        <span>Purchase Failed</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-sm-12">
                    <div class="checkout-box">
                        <div v-if="isErrored" class="row">
                            <div class="col-12 text-center">
                                <p class="errored-text">
                                    <i class="fas fa-times"></i><br>
                                    Something went wrong
                                </p>
                            </div>
                        </div>

                        <div v-if="!isErrored && isLoading" class="row">
                            <div class="col-12 text-center">
                                <p class="loading-text">
                                    <i class="fas fa-circle-notch fa-spin"></i><br>
                                    Loading
                                </p>
                            </div>
                        </div>

                        <div v-if="!isErrored && !isLoading" class="row">
                            <div class="col-lg-12 col-sm-12 text-center">
                                <p class="subdetails-header" v-html="errorMessage"></p>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        We've had some difficulties while processing your payment.
                                        <br>
                                        You can try again to complete your payment.
                                        <br>
                                        If this issue persists, please contact us.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckoutError',
    data(){
        return {
            isErrored: false,
            isLoading: false,
            errorMessage: this.$route.query.message ?? 'Something went wrong with your payment'
        }
    },
    methods: {
        
    },
    created(){
        
    }
}
</script>

<style lang="scss" scoped src="@/assets/store/checkout/checkout.scss">

</style>
<style scoped>
.padding-lg{
    padding: 10vh 0;
}
</style>