<template>
    <div id="StatusView">
        <Navbar />

        <Services />

        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import Services from '@/components/Status/Statuses.vue';

export default {
    name: 'StatusView',
    components: {Navbar, Footer, Services}
}
</script>

<style>

</style>