<template>
    <div id="HeaderPromo">
        <div id="PromoBody">
            <div class="bg-main"></div>
            <div class="container-fluid"> 
                <div class="bg-archer"></div>
                <div class="row upper-layer">
                    <div class="col-lg-3 offset-lg-1 text-center">
                        <div class="promo-title tracking-in-expand-fwd">
                            <img src="../../assets/img/next-gen.png" class="nextgen-image" alt="NextGenMMORPG">
                        </div>
                    </div>
                    <div class="col-lg-4 offset-lg-3 text-center">
                        <div class="promo-desc">
                            <img src="../../assets/img/rpg.png" class="rpg-image" alt="ValeniaRPG">
                        </div>
                        <div  class="promo-button-wrapper">
                            <router-link to="/redirect/invite" class="promo-button v-button col-lg-8">
                                <span class="txt">Add to DISCORD</span>
                            </router-link>
                            <a href="#ClassDisplay" v-smooth-scroll class="promo-button v-button col-lg-8">
                                <span class="txt">See Features</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-bottom-frame"></div>
    </div>
</template>

<script>
export default {
    name: "HeaderPromo",
    data(){
        return {
            area_picture: "https://plchldr.co/i/1000x500?&bg=212121&fc=f5f5f5&text=combat_image"
        }
    }
}
</script>

<style scoped lang="scss" src="@/assets/general.scss">
</style>

<style lang="scss" src="@/assets/home/header.scss">
</style>