<template>
    <div id="Challenge">

        <div class="challenge-wrapper">
            <div class="container-fluid">
                <div class="row">
                    
                    <div class="col-lg-5 offset-lg-1">
                        <img class="image" src="../../assets/img/duel.png" alt="mob picture">
                    </div>

                    <div class="col-lg-5" style="margin-top: 12vh;padding-left: 3vw;">
                        <div class="heading">
                            Challenge Other Players
                            <div class="title-line"></div>
                        </div>
                        <p class="desc" style="">
                            Defeat everyone upon you,
                            and become the strongest player
                            on Discord!
                        </p>
                    </div>

                    
                </div>
            </div>
        </div>

    </div>
</template>



<style scoped>
.challenge-wrapper{
    background-image: url("../../assets/img/challenge_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 10vh 0;
}
.title-line{
    background-image: url('../../assets/img/header_bottom_frame.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 6vh;
    margin-top: -1vh;
}
.image {
    width: 100%;
}
.heading{
    font-size: 2.5em;
    font-family: griffon,serif;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3vh;
    color: #E6E6E9;
    text-align: center;
}
.desc{
    color: #9999A1;
    font-size: 1.4em;
}

p.heading{
    font-size: 3.5em;
    font-weight: bold;
    color: #f5f5f5;
}
div.par-heading{
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 3vh;
    color: #E6E6E9;
}
.descriptor{
    color: #9999A1;
    font-size: 1em;
}
.white-hr{
    border-color: #f5f5f5;
}
</style>