<template>
    <div id="CheckoutView">
        <Navbar />

        <PaymentComplete />

        <Footer />
    </div>
</template>

<script>
import Navbar from '../../components/Navbar.vue';
import Footer from '../../components/Footer.vue';
import PaymentComplete from '../../components/Store/PaymentResult.vue';

export default {
    name: "PaymentCompleteView",
    components: {Navbar, Footer, PaymentComplete}
}
</script>

<style>

</style>