import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import scroll from 'vue3-smooth-scroll'
import AOS from 'aos'
import lazyload from 'vue3-lazyload';

createApp(App)
    .use(store)
    .use(router)
    .use(scroll)
    .use(AOS.init())
    .use(lazyload)
    .mount('#app')
