<template>
  <div id="MesafeliSatisSozlesmesi">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-6 offset-lg-3 col-sm-12 satis-sozlesmesi">
                  <div id="contract" v-html="contractHTML"></div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'MSS',
    data(){
        return {
            contractHTML: ''
        }
    },
    methods: {
        getContract(){
            axios.post(`${this.$store.getters.server}/store/get/distance-contract`, {
                invoiceDetails: this.$store.state.invoiceDetails
            })
            .then(response => {
                this.contractHTML = response.data;
            })
        }
    },
    created(){
        this.getContract();
    }
}
</script>

<style>
    #MesafeliSatisSozlesmesi{
        background-color: white;
    }
    .satis-sozlesmesi{
        background-color: white;
        border: 2px solid black;
        padding: 20px;
    }
</style>