<template>
    <div id="Redirecting">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 offset-lg-4 col-sm-12">
                    <div class="card border-light mt-5">

                        <div v-if="isRedirectValid()" class="card-body">
                            <div class="text-center">
                                <h1>Redirecting...</h1>
                                <p>You will be redirected in a few seconds.</p>
                            </div>
                        </div>
                        <div v-else class="card-body">
                            <div class="text-center">
                                <h1>Unable to find page...</h1>
                                <p>Seems like you used a wrong redirect url, redirecting you back to home...</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RedirectView",
    data: () => {
        return {
            urls: new Map([
                ['official-server', 'https://discord.gg/valenia'],
                ['invite', 'https://discord.com/oauth2/authorize?client_id=759843350810984478&scope=bot&permissions=139586817024'],
                ['status', 'https://valenia.statuspage.io'],
            ])
        }
    },
    methods: {
        redirect(){
            if(this.isRedirectValid()){
                const page = this.$route.params.page;
                setTimeout(() => {
                    window.location = this.urls.get(page);
                }, 3000);
            }
            else{
                this.$router.push('/');
            }
        },
        isRedirectValid(){
            const page = this.$route.params.page;
            return this.urls.has(page);
        }
    },
    created(){
        this.redirect();
    }
}
</script>

<style>

</style>