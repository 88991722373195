<template>
    <div id="PremiumTable">
        <div class="container-fluid mt-5 pb-5">
            <div class="row">

                <div class="col-lg-10 offset-lg-1 col-md-12">
                    <table class="table table-borderless premium-table">
                        <thead>
                            <tr class="titles">
                                <td class="col-4 features-title">Features</td>
                                <td v-for="(premium, index) in premiums" :key="index" :class="columnClass(index, true, true)" class="text-center col-2 bordered-default pbg">
                                    <img :src="premium.image" alt="Premium Framed Symbol">
                                    <span>{{premium.name}}</span>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="" v-for="feature in processedFeatures" :key="feature.desc">
                                <td v-html="feature.desc"></td>
                                <td v-for="(status, index) in feature.available" :key="index" :class="columnClass(index, status)" class="text-center pbg">
                                    <i v-if="status === true" class="fas fa-check"></i>
                                    <span v-else-if="status === false"></span>
                                    <span v-else v-html="status"></span>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td v-for="(pkg, pkgIndex) in packages" :key="pkg._id">
                                    <div class="dropdown">
                                        <a :class="`col-12 btn buy-btn ${buyButtonClasses[pkgIndex]}`" href="https://www.patreon.com/valenia" target="_blank">
                                            Buy Now
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "PremiumTable",
    data(){
        return {
            columnClass: (index, content, isHeader) => {
                content = true;
                return {
                    'common': index === 0 && content,
                    'uncommon': index === 1 && content,
                    'rare': index === 2 && content,
                    'epic': index === 3 && content,
                    'legendary': index === 4 && content,
                    'mythic': index === 5 && content,
                    'ultimate': index === 6 && content,
                    'unbordered': !isHeader,
                    'col-header': isHeader
                }
            },
            processedFeatures: [],
            buyButtonClasses: [
                'common',
                'uncommon',
                'rare',
                'epic',
                'legendary',
                'mythic',
                'ultimate'
            ],
            packages: [],
            premiums: [
                {name: 'Common', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/common.png'},
                {name: 'Uncommon', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/uncommon.png'},
                {name: 'Rare', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/rare.png'},
                {name: 'Epic', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/epic.png'},
                {name: 'Legendary', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/legendary.png'},
                {name: 'Mythic', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/mythic.png'},
                {name: 'Ultimate', image: 'https://eu2.contabostorage.com/58ff8a3d3f7f4df7adc0143d9f8208e5:valenia/website/premium-frames/ultimate.png'},
            ],
            features: [
                {desc: 'Premium Frames', available: [true, true, true, true, true, true, true]},
                {desc: 'Premium Badge', available: [true, true, true, true, true, true, true]},
                {desc: 'Premium Discord Role', available: [true, true, true, true, true, true, true]},
                {desc: 'Premium Menu', available: [true, true, true, true, true, true, true]},
                {desc: 'Additional XP Gain', available: ['10%','20%','35%','50%','75%','125%','200%']},
                {desc: 'Cooldown decrease in commands', available: ['-10%','-15%','-25%','-35%','-45%','-55%','-75%']},
                {desc: 'Achievements', available: [true, true, true, true, true, true, true]},
                {desc: 'Nickname Change', available: [true, true, true, true, true, true, true]},
                {desc: 'Extra Character Slot', available: ['+1','+1','+1','+2','+2','+3','+4']},
                {
                    desc: 'Daily Reward', 
                    available: [
                        'Common Chest', 
                        'Uncommon Chest',
                        'Rare Chest',
                        'Epic Chest',
                        'Legendary Chest',
                        'Mythic Chest',
                        'Ultimate Chest'
                    ]
                },
                {desc: 'Item Comparison', available: [true, true, true, true, true, true, true]},
                {desc: 'Discount', available: ['5%', '10%', '15%', '25%', '40%', '55%', '75%']},
                {
                    desc: 'Inventory Upgrade', 
                    available: [
                        '+18 Slot',
                        '+18 Slot',
                        '+18 Slot',
                        '+18 Slot <br> Unlock All Spaces',
                        '+18 Slot <br> Unlock All Spaces',
                        '+18 Slot <br> Unlock All Spaces <br> Reorder Button',
                        '+18 Slot <br> Unlock All Spaces <br> Reorder Button',
                    ]
                },
                {
                    desc: 'Bank Upgrade', 
                    available: [
                        '+18 Slot',
                        '+18 Slot',
                        '+18 Slot',
                        '+18 Slot <br> Unlock All Spaces',
                        '+18 Slot <br> Unlock All Spaces',
                        '+18 Slot <br> Unlock All Spaces <br> Reorder Button',
                        '+18 Slot <br> Unlock All Spaces <br> Reorder Button',
                    ]
                },
                {desc: 'Upvote Reward', available: [false, true, true, true, true, true, true]},
                {desc: 'Gold Gain', available: [false, false, '10%', '20%', '35%', '50%', '75%']},
                {desc: 'Auto Repair', available: [false, false, false, true, true, true, true]},
                {desc: 'Daily Quest Upgrades', available: [false, false, false, false, 'Legendary Quests <br> 2 Quest Rerolls', 'Legendary Quests <br> 3 Quest Rerolls', 'Legendary Quests <br> 4 Quest Rerolls']},
                {desc: 'Auto Revive', available: [false, false, false, false, true, true, true]},
                {desc: 'Greet Message by <strong>Valenia</strong>', available: [false, false, false, false, false, true, true]},
                {desc: 'Personal Support', available: [false, false, false, false, false, false, true]},
            ],
        }
    },
    methods: {
        buyPremium(packageId, timeshort){
            this.$store.commit('setBuyingPremium', packageId);
            this.$store.commit('setBuyingTimeshort', timeshort);
            this.$router.push('/checkout')
        },
        async getPremiums(){
            const prices = await axios.get(`${this.$store.getters.server}/store/get/packages`);
            this.packages = prices.data.packages;
        },
        prepareFeatures(){
            const knownFeatures = [
                {desc: 'Premium Frames', code: 'premium_frames'},
                {desc: 'Premium Badge', code: 'premium_badge'},
                {desc: 'Premium Discord Role', code: 'premium_discord_role'},
                {desc: 'Premium Menu', code: 'premium_menu'},
                {desc: 'Nickname Change', code: 'nickname_change'},
                {desc: 'Item Comparison', code: 'item_comparison'},
                {desc: 'XP Bonus', code: 'xp_bonus'},
                {desc: 'Cooldown Decrease', code: 'cooldown_decrease'},
                {desc: 'Extra Character Slot', code: 'extra_character_slot'},
                {desc: 'Discounts', code: 'discounts'},
                {desc: 'Inventory Upgrade', code: 'inventory_upgrade'},
                {desc: 'Bank Upgrade', code: 'bank_upgrade'},
                {desc: 'Gold Gain', code: 'gold_gain'},
                {desc: 'Auto Repair', code: 'auto_repair'},
                {desc: 'Auto Revive', code: 'auto_revive'},
                {desc: 'Skip Battle Log', code: 'skip_battle_log'},
                {desc: 'Greet Message', code: 'greet_message'},
                {desc: 'Personal Support', code: 'personal_support'},
            ]
            this.processedFeatures = []

            knownFeatures.forEach(feature =>{
                let f = {
                    desc: feature.desc,
                    available: [],
                }
                this.packages.forEach(pkg =>{
                    const packageFeature = pkg.features.find(x => x.name === feature.code);
                    f.available.push(packageFeature.value);
                });
                this.processedFeatures.push(f);
            });
        }
    },
    async created(){
        await this.getPremiums();
        this.prepareFeatures();
    }
}
</script>
<style lang="scss" src="@/assets/store/premium-table/table.scss">
</style>
