<template>
    <div id="PremiumFeatures">
        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="row">
                        <div class="col-lg-4 offset-lg-4 col-12">
                            <img class="premium-page-title" src="../../assets/img/premium.png" alt="Premium Title">
                            <!-- <p class="page-title tracking-in-expand">Valenia Premium</p>
                            <hr style="border-color: white">-->
                        </div>
                    </div>
                    <!--<p data-aos="fade-up" class="sub-title">
                        An exclusive way to play Valenia
                    </p>-->
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-lg-10 offset-lg-1 col-md-12 text-center">
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>

<style scoped>
@-webkit-keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}@keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}
.tracking-in-expand{-webkit-animation:tracking-in-expand 1s cubic-bezier(.215,.61,.355,1.000) both;animation:tracking-in-expand 1s cubic-bezier(.215,.61,.355,1.000) both}
.premium-page-title{
    width: 100%;
    max-width: 60vh;
}
.page-title{
    font-family: nocturne-serif, serif;
    font-size: 3.5em;
    color: #F7DE78;
}
.sub-title{
    font-family: nocturne-serif, serif;
    font-size: 1.5em;
    color: #9999A1;
}
</style>