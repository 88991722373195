<template>
    <div id="Home">
        <Navbar />
        <HeaderPromo />
        <!-- <Stats /> -->
        <CreateDivider />
        <ClassDisplay />
        <MobFeatured />
        <SectionDivider />
        <ChestFeatured />
        <SectionDivider />
        <ChallengeOthers />
        <SectionDivider />
        <PremiumFeatured />
        <SectionDivider />
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import HeaderPromo from '@/components/Home/HeaderPromo.vue';
import CreateDivider from '@/components/Home/FirstDivider.vue';
import ClassDisplay from '@/components/Home/ClassDisplay.vue';
import MobFeatured from '@/components/Home/MobFeatured.vue';
import ChestFeatured from '@/components/Home/ChestFeatured.vue';
import PremiumFeatured from '@/components/Home/PremiumFeatured.vue';
import ChallengeOthers from '@/components/Home/Challenge.vue';
import SectionDivider from '@/components/Home/SectionDivider.vue';
// import Stats from '@/components/Home/Stats.vue';

export default {
    name: "Home",
    components: {
        Navbar,
        Footer,
        HeaderPromo,
        MobFeatured,
        ChestFeatured,
        ChallengeOthers,
        CreateDivider,
        ClassDisplay,
        PremiumFeatured,
        SectionDivider,
        // Stats
    }
}
</script>

<style src="@/assets/home/home.css">

</style>