<template>
    <div id="ClassDisplay">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <p class="section-title" style="user-select: none;">
                        CLASSES
                    </p>
                    <div class="title-line"></div>
                </div>
            </div>
            <div class="row character-list mt-5">
                <div class="col-10 offset-lg-1">
                    <div class="row character-wrapper">

                        <div class="col-lg-3 col-sm-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-center character-name">Archer</h3>
                                    <div class="image-holder">
                                        <img class="character-image" src="../../assets/img/frames/archer.png" alt="">
                                    </div>
                                    <img class="character-symbol" src="../../assets/img/symbols/archer.png" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-center character-name">ASSASSIN</h3>
                                    <div class="image-holder">
                                        <img class="character-image" src="../../assets/img/frames/assassin.png" alt="">
                                    </div>
                                    <img class="character-symbol" src="../../assets/img/symbols/assassin.png" alt="">
                                </div>
                            </div>
                        </div>

                        

                        <div class="col-lg-3 col-sm-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-center character-name">Mage</h3>
                                    <div class="image-holder">
                                        <img class="character-image" src="../../assets/img/frames/mage.png" alt="">
                                    </div>
                                    <img class="character-symbol" src="../../assets/img/symbols/mage.png" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="text-center character-name">WARRIOR</h3>
                                    <div class="image-holder">
                                        <img class="character-image" src="../../assets/img/frames/warrior.png" alt="">
                                    </div>
                                    <img class="character-symbol" src="../../assets/img/symbols/warrior.png" alt="">
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClassDisplay',
    data(){
        return {
            hover: {
                Archer: false,
                Assasin: false,
                Mage: false,
                Warrior: false,
            },
            characters: [
                {
                    name: 'Archer',
                    image: '/assets/img/frames/archer.png',
                },
                {
                    name: 'Assassin',
                    image: 'https://via.placeholder.com/300x450?text=Character',
                },
                {
                    name: 'Mage',
                    image: 'https://via.placeholder.com/300x450?text=Character',
                },
                {
                    name: 'WARRIOR',
                    image: 'https://via.placeholder.com/300x450?text=Character',
                }
            ]
        }
    }
}
</script>

<style>
@import "https://use.typekit.net/irx4uvw.css";
@-webkit-keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}@keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@-webkit-keyframes scale-down-center{0%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(.5);transform:scale(.5)}}@keyframes scale-down-center{0%{-webkit-transform:scale(1);transform:scale(1)}100%{-webkit-transform:scale(.5);transform:scale(.5)}};
@import url('https://use.typekit.net/edx8ekp.css');
#ClassDisplay {
    min-height: 90vh;
    background-image: url("../../assets/img/classd_bg.png");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 5vh;
}
.title-line{
    background-image: url('../../assets/img/header_bottom_frame.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 6vh;
    margin-top: -4vh;
}
.section-title {
    margin-top: 5vh;
    font-family: griffon,serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 2px;
    font-size: 4.3em;
    color: #C3CBC8;
}
.character-wrapper {
    margin-bottom: 7vh;
}
.character-image {
    transition: all linear .3s;
    z-index: 4;
    width: 100%;
    margin: 0 0;
}
.image-holder {
    background-size: cover;
    display: block;
    min-height: 25vh;
}
.character-name {
    width: 100%;
    color: #f5f5f5;
    margin-top: 1vh;
    font-family: griffon,serif;
    font-weight: 400;
    font-style: normal;
    user-select: none;
    font-size: 2.3em;
}
@keyframes go-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
.character-symbol {
    width: 40%;
    margin: 0 auto;
    display: block;
}
.character-symbol:hover {
    animation: go-up 1s ease 0 infinite;
}
</style>