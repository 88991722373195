<template>
    <div id="CheckoutBody">
        <div class="container-fluid padding-lg">
            <div class="row">
                <div class="col-8 offset-2">
                    <div class="checkout-title">
                        <span v-if="isErrored">Purchase Failed</span>
                        <span v-else>Purchase Successful</span>
                    </div>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-8 offset-2">
                    <div class="checkout-box">
                        
                        <div v-if="isErrored" class="row">
                            <div class="col-12 text-center">
                                <p class="errored-text">
                                    <i class="fas fa-times"></i><br>
                                    Something went wrong
                                </p>
                            </div>
                        </div>

                        <div v-if="!isErrored && isLoading" class="row">
                            <div class="col-12 text-center">
                                <p class="loading-text">
                                    <i class="fas fa-circle-notch fa-spin"></i><br>
                                    Loading
                                </p>
                            </div>
                        </div>

                        <div v-if="!isErrored && !isLoading" class="row">
                            <div class="col-lg-6 col-sm-12">
                                <p class="subdetails-header">Subscription Details</p>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        Hey <strong>{{user.username}}!</strong><br>
                                        <br/>
                                        Your payment has been successfully processed.
                                        <br>
                                        And your <strong>{{ result.packageDetails.name }} ({{ result.packageDetails.time.name }})</strong> subscription has started!
                                        <br/>
                                        It will end on <strong>{{packageEndDate}}</strong>.
                                    </div>
                                </div>

                                <p class="subdetails-header">Payment Information</p>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <ul>
                                            <li>
                                                <strong>Receipt Number:</strong> {{ result.invoice.receiptId }}
                                            </li>
                                            <li>
                                                <strong>Payment Method:</strong> {{paymentMethod}}
                                            </li>
                                            <li>
                                                <strong>Amount:</strong> ${{ result.invoice.subscription.amount.usd }}
                                            </li>
                                        </ul>
                                        <p>
                                            Your receipt will be ready in 1-3 bussiness days and will be sent 
                                            to your e-mail address.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
import moment from 'moment';

export default {
    name: 'PaymentResult',
    data(){
        return {
            isLoading: true,
            isErrored: false,
            user: this.$store.getters.session,
            pkg: this.$store.state.boughtPackage,
        }
    },
    computed: {
        packageEndDate(){
            const milliseconds = this.result.packageDetails.time.numerical ?? 0;
            return moment(Date.now() + milliseconds).format('DD MMMM YYYY').toString();
        },
        paymentMethod(){
            const methods = ['Credit Card', 'Credit Card with 3D Secure'];
            return methods[0];
        }
    },
    methods: {
        async getPurchaseResult(){
            const token = this.$route.query.token;
            try{
                const response = await axios.post(`${this.$store.getters.server}/store/get-purchase-result`, {token});
                this.isLoading = false;
                if(response.data.result === 1){
                    this.result = response.data.data
                }else{
                    this.isErrored = true;
                }
            }
            catch(err){
                this.isLoading = false;
                this.isErrored = true;
            }
        }
    },
    async created(){
        // if(!this.$route.params.token || this.$route.params.token == null) {
        //     this.isErrored = true;
        //     this.$router.push('/');
        //     return;
        // }
        await this.getPurchaseResult();
        this.$store.commit('clearBoughtPackage');
    }
}
</script>

<style lang="scss" scoped src="@/assets/store/checkout/checkout.scss">

</style>
<style scoped>
.padding-lg{
    padding: 10vh 0;
}
</style>