<template>
    <div id="ServiceStatuses">
        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-10 offset-1">
                    <p class="page-title">
                        Valenia Status
                    </p>
                    <hr style="border-color: #f5f5f5;">
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-10 offset-1">
                    <table class="table table-borderless">
                        <thead></thead>
                        <tbody>
                            <template v-for="(service, index) in services" :key="index">
                                <tr class="row mt-5">
                                    <td class="service-grouptitle">{{service.group}}</td>
                                </tr>

                                <tr v-for="(s, index) in service.services" :key="index" class="row">
                                    <td class="col-6">
                                        <p class="service-title">
                                            {{s.name}}
                                        </p>
                                    </td>
                                    <td class="col-3 offset-3 text-right">
                                            <span v-if="s.status === 2">
                                                <p class="service-status text-success">
                                                    <i class="fas fa-check"></i> Operational
                                                </p>
                                            </span>
                                            <span v-else-if="s.status === 1">
                                                <p class="service-status text-warning">
                                                    <i class="fas fa-exclamation"></i> Degraded Performance
                                                </p>
                                            </span>
                                            <span v-else-if="s.status === 0">
                                                <p class="service-status text-warning">
                                                    <i class="fas fa-exclamation-circle"></i> Partial Outage
                                                </p>
                                            </span>
                                            <span v-else-if="s.status === -1">
                                                <p class="service-status text-danger">
                                                    <i class="fas fa-times-circle"></i> Major Outage
                                                </p>
                                            </span>
                                            <span v-else-if="s.status === -2">
                                                <p class="service-status text-info">
                                                    <i class="fas fa-info-circle"></i> Under Maintenance
                                                </p>
                                            </span>
                                            <span v-else-if="s.status === -3">
                                                <p class="service-status text-info">
                                                    <i class="fas fa-circle-notch fa-spin"></i> Coming Soon
                                                </p>
                                            </span>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Services",
    data(){
        return {
            services: [
                {
                    group: 'Web Services',
                    services: [
                        {
                            name: "Website",
                            status: 2
                        },
                        {
                            name: "Shop",
                            status: 2
                        }
                    ]
                },
                {
                    group: 'Game Services',
                    services: [
                        {
                            name: "Bot Service",
                            status: 2
                        },
                        {
                            name: "Backend Service",
                            status: 1
                        },
                        {
                            name: "Valenia Public API",
                            status: -3
                        },
                    ]
                }
            ]
        }
    }
}
</script>

<style>
@import url('https://use.typekit.net/edx8ekp.css');
.page-title{
    font-family: nocturne-serif, serif;
    font-size: 2.5em;
    color: #f5f5f5;
}
.service-grouptitle{
    font-family: nocturne-serif, serif;
    font-size: 2em;
    color: #f5f5f5;
}
.service-title{
    font-family: nocturne-serif, serif;
    color: #f5f5f5;
    font-size: 1.7em;
    opacity: 0.8;
    padding-left: 1vw;
}
</style>