<template>  
    <div id="Login">
        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-4 offset-4">
                    <div class="card bg-light text-center">
                        <div class="card-body">
                            <i class="fas fa-spinner fa-spin"></i> Redirecting to Discord for authentication
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Login",
    created(){
        setTimeout(() => {
            window.location.href = `${this.$store.getters.server}/auth`;
        }, 2000);
    }
}
</script>

<style>

</style>