<template>
  <div id="ContactForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 offset-lg-1 col-sm-12">
          <div class="box-title">
            Contact Us
          </div>
          <div class="contact-box">
            <div class="box-body">
              <div class="row">

              <div class="col-lg-6 col-sm-12">
                <div class="contact-text">
                  <p>
                    You can contact with us about any problems
                    or questions you have. We will answer you 
                    as soon as possible. You can use the options 
                    below to contact or send us a message.
                  </p>

                  <ul class="contact-options">
                    <li class="option">
                      <span class="icon">
                        <i class="fas fa-envelope"></i>
                      </span>
                      <a class="link-option" href="mailto:info@testservergames.com">
                      info@testservergames.com
                      </a>
                    </li>

                    <li class="option">
                      <span class="icon">
                        <i class="fab fa-discord"></i>
                      </span>
                      <a class="link-option" href="https://discord.gg/valenia">
                        Valenia Official Server
                      </a>
                    </li>

                    <li class="option">
                      <span class="icon">
                        <i class="fas fa-map"></i>
                      </span>
                      <span class="text-option">
                        Kadikoy/Istanbul, Turkiye
                      </span>
                    </li>

                    <li class="option">
                      <span class="icon">
                        <i class="fas fa-phone"></i>
                      </span>
                      <span class="text-option">
                        +90 507 153 6360
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-lg-6 col-sm-12">

                <div class="row mt-2">
                  <div class="col-lg-6 col-sm-12">
                    <label for="">Name: (*)</label>
                    <input type="text" v-model="form.name" class="form-control" placeholder="John Doe">
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-6 col-sm-12">
                    <label for="">Discord:</label>
                    <input type="text" v-model="form.discord" class="form-control" placeholder="John#0001">
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-6 col-sm-12">
                    <label for="">E-mail: (*)</label>
                    <input type="email" v-model="form.email" class="form-control" placeholder="john@doe.com">
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-lg-8 col-sm-12">
                    <label for="">Message: (*)</label>
                    <textarea v-model="form.message" rows="6" class="form-control"></textarea>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-lg-4 col-sm-12">
                    <button class="btn btn-primary col-12" ref="submitButton" @click="sendMessage">Send</button>
                  </div>
                </div>

              </div>

            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';

export default {
  name: 'ContactForm',
  data() {
    return {
      form: {
        name: '',
        email: '',
        discord: '',
        message: ''
      }
    }
  },
  methods: {
    async sendMessage(){
      const button = this.$refs.submitButton;
      this.setProcessButton(button);
      if(this.validateForm()){
        try{
          const r = await this.sendMessageRequest();
          if(r.result === 1){
            swal.fire({
              title: 'Success',
              text: 'Your message has been sent successfully.',
              icon: 'success'
            });
          }
          else {
            swal.fire({
              title: 'Error',
              text: 'An error has occurred. Please try again later.',
              icon: 'error'
            });
          }
        }
        catch(e){
          swal.fire({
            title: 'Error',
            text: 'An error has occurred. Please try again later.',
            icon: 'error'
          });
        }
        this.resetForm();
      }
      else {
        swal.fire('Oops!', 'Please fill all the fields', 'error');
      }

      // and then set the button to the default state
      this.setNormalButton(button);
    },
    setProcessButton(button){
      button.disabled = true;
      button.innerHTML = `<i class="fas fa-circle-notch fa-spin"></i> Sending`;
    },
    setNormalButton(button){
      button.disabled = false;
      button.innerHTML = `Send`;
    },
    validateForm(){
      const nameCheck = this.form.name.length > 0 && this.form.name.length < 64;
      const emailCheck = this.form.email.length > 0 && this.form.email.length < 128;
      const messageCheck = this.form.message.length > 0 && this.form.message.length < 2048;

      return nameCheck && emailCheck && messageCheck;
    },
    sendMessageRequest(){
      return new Promise((resolve, reject) => {
        const data = {
          name: this.form.name,
          email: this.form.email,
          discord: this.form.discord,
          message: this.form.message
        }

        axios.post(`${this.$store.getters.server}/contact/send`, data)
        .then(response => resolve(response.data))
        .catch(error => reject(error));
      });
    },
    resetForm(){
      this.form.name = '';
      this.form.email = '';
      this.form.discord = '';
      this.form.message = '';
    }
  }
}
</script>

<style scoped lang="scss" src="@/assets/contact/contact.scss">

</style>